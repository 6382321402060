<template>
    <v-app>
        <v-layout>
            <v-app-bar class="app-main-header" ref="appBar">
                <template v-slot:prepend>
                    <v-avatar class="logo" :image="logo" @click="showDrawer"></v-avatar>
                </template>
                <template v-slot:title>
                    <div class="app-name">
                        <NuxtLink :to="localePath({ name:'app' })">
                            <v-btn variant="plain" color="primary" size="x-large"
                                   :text="config.public.appName"
                                   class="text-high-emphasis"></v-btn>
                        </NuxtLink>
                    </div>
                    <v-container fluid v-if="!isMobile">
                        <menu-e-menu name="breadcrumb" :display-mode="MenuDisplayMode.breadcrumb"></menu-e-menu>
                    </v-container>
                </template>
                <template v-slot:append>
                    <div class="d-flex align-center">
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn color="primary"
                                       v-bind="props">
                                    <country-flag :country="getCountryCode(localeProperties.language)" size='small'></country-flag>
                                </v-btn>
                            </template>
                            <v-list v-if="!!availableLocales.length">
                                <v-list-item v-for="localeItem in availableLocales"
                                             :key="localeItem.code"
                                             :value="localeItem.code" :title="localeItem.name"
                                             @click.prevent="switchLocale(localeItem.code)">
                                    <template v-slot:prepend>
                                        <country-flag :country="getCountryCode(localeItem.language)" size='small'
                                                      class="mr-1"></country-flag>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-list class="pt-0 pb-0">
                            <v-list-item v-if="loggedIn" class="user-info d-flex">
                                <v-menu bottom
                                        min-width="200px"
                                        rounded
                                        offset-y>
                                    <template v-slot:activator="{ props }">
                                        <div class="d-flex align-center">
                                            <v-btn icon
                                                   x-large
                                                   v-bind="props"
                                                   class="text-none">
                                                <v-avatar color="blue" size="48">
                                                    <span class="white--text headline">{{ initials }}</span>
                                                </v-avatar>
                                            </v-btn>
                                            <NuxtLink v-if="!isMobile" :to="localePath({name: 'app-account'})"
                                                      class="link-no-decoration pl-3" v-html="fullName">
                                            </NuxtLink>
                                        </div>
                                    </template>
                                    <v-list>
                                        <v-list-item v-if="isMobile">
                                            <NuxtLink :to="localePath({name: 'app-account'})"
                                                      class="link-no-decoration" v-html="fullName">
                                            </NuxtLink>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-btn depressed class="text-none"
                                                       rounded
                                                       text
                                                       v-on:click="singout">
                                                    {{ singOutBtnText }}
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item>
                            <v-list-item v-else class="user-info pl-0 pr-0">
                                <NuxtLink :to="localePath({name: 'app-auth-login'})" class="menu-item">
                                    <v-btn class="ma-2 text-none"
                                           outlined :text="loginBtnText"
                                           color="primary"></v-btn>
                                </NuxtLink>
                            </v-list-item>
                        </v-list>
                    </div>
                </template>
                <template v-if="isMobile" v-slot:extension>
                    <div class="w-100">
                        <menu-e-menu name="breadcrumb" :display-mode="MenuDisplayMode.breadcrumb"></menu-e-menu>
                    </div>
                </template>
            </v-app-bar>
            <v-navigation-drawer rail expand-on-hover app v-model="displayDrawer">
                <menu-e-menu name="main" :display-mode="MenuDisplayMode.list"></menu-e-menu>
            </v-navigation-drawer>
            <v-main>
                <v-container fluid>
                    <Suspense>
                        <slot />
                    </Suspense>
                    <loading-e-loading></loading-e-loading>
                </v-container>
            </v-main>
            <v-footer :absolute="true" app class="justify-center flex-column">
                <footer-e-footer></footer-e-footer>
                <v-container class="d-flex justify-center">
                    <span>&copy; {{ appActiveYear }}</span>
                </v-container>
            </v-footer>
        </v-layout>
    </v-app>
</template>

<script setup lang="ts">
    import { refreshMenuItems } from "~/parts/menu";
    import { useResizeObserver } from "@vueuse/core";
    import CountryFlag from "vue-country-flag-next";
    import logo from "~/public/pwa-192x192.png";
    import { type GlobalResources as Gr } from "i18n-resources";
import "@sweetalert2/theme-dark/dark.css";

    //#region Data
    const nuxtApp = useNuxtApp();
    const localePath = useLocalePath();
    const switchLocalePath = useSwitchLocalePath();
    const appBar = ref(null);
    const appStartingYear = 2020;
    const { initials, fullName, hasAccessAsync } = await useCurrentUser();
    const isMobile = useIsMobile();
    const headerHeightMax = ref<number>(0);
    const displayDrawer = ref(true);
    const loading = useLoading();
    const { loggedIn, onSignOut, signOut: disconnect } = await useEAuth();
    const config = useRuntimeConfig();
    const { locale, locales, localeProperties, setLocale, t, setLocaleCookie } = useELocale();
    const { bottom: appBarBottom } = useElementBounding(appBar);
    //#endregion

    //#region Computed
    const drawerPaddingTop = computed(() => appBarBottom.value );
    const appActiveYear = computed(() => {
        const currentYear = new Date().getFullYear();
        return currentYear == appStartingYear ? appStartingYear.toString() : `${appStartingYear} - ${currentYear}`;
    });
    const availableLocales = computed(() => {
        return locales.value.filter(i => i.code !== locale.value)
    });
    const singOutBtnText = computed(() => t<Gr>(gr => gr.singOut));
    const loginBtnText = computed(() => t<Gr>(gr => gr.loginBtn));
    //#endregion

    //#region Methods
    useResizeObserver(appBar, (e, _) => {
        headerHeightMax.value = e[0].contentRect.height;
    });

    async function singout() {
        await loading.show(async () => {
            await disconnect({ redirect: false });
        });
    }

    function showDrawer() {
        displayDrawer.value = !displayDrawer.value;
    }

    function onKeepSession() {
        //this.http().get('keepSession/');
    }

    function getCountryCode(localIsoCode: string) {
        return localIsoCode.split("-")[1].toLowerCase();
    }

    async function switchLocale(localeCode: string) {
        setLocaleCookie(localeCode);
        const path = switchLocalePath(localeCode);
        useRouter().push(path);
    }
    //#endregion

    //#region Hooks
    onBeforeMount(() => {
        displayDrawer.value = !isMobile.value;
    });

    onSignOut(async () => {
        await refreshMenuItems(nuxtApp);
        const path = localePath({ name: "app-auth-login" });
        await navigateTo({ path });
    });
    //#endregion
</script>

<style lang="scss">

    .app-main-header {
        overflow: visible;
    }

    .logo:hover {
        cursor: pointer;
    }

    .header.v-toolbar .v-toolbar__content .row + .row {
        margin-top: 0;
    }

    .header .v-list-item__title {
        flex: 1 1 10% !important;
    }

    .header .v-list-item__content {
        flex: 1 1 100% !important;
    }

    a.menu-item {
        text-decoration: none;
    }

    .app-name .text-high-emphasis {
        opacity: 1 !important;
    }

    .user-info {
        flex-shrink: 5 !important;
    }

    .v-application .v-btn {
        text-transform: none !important;
    }

    .v-navigation-drawer {
        position: fixed !important;
        height: 100vh !important;
        top: 0px !important;
        padding-top: calc(v-bind(drawerPaddingTop) * 1px) !important;
        margin-top: 0px !important;
    }

    .v-toolbar-title {
        min-width: auto;

        .v-toolbar-title__placeholder {
            display: flex;
            align-items: center;
        }
    }

    .link-no-decoration {
        text-decoration: none !important;
    }

    .v-toolbar__extension {
        align-items: flex-start !important;
    }

    .v-toolbar__content, .v-toolbar-title__placeholder {
        overflow: visible;
    }

    body.swal2-shown {
        padding-right: 0px !important;
    }
</style>
