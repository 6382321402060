<template>
    <v-overlay class="loading-container" :model-value="display"
               persistent>
        <v-progress-circular indeterminate class="loading"
                             color="primary"></v-progress-circular>
    </v-overlay>
</template>

<script setup lang="ts">
    import { storeToRefs } from "pinia";
    import { loadingStore } from "~/stores/loading/e.loading.store";

    //#region Props
    const props = defineProps(
        {
            visible: {
                type: Boolean,
                default: false
            }
        }
    );
    //#endregion

    //#region Data
    const show = ref(props.visible);
    //#endregion

    //#region Computed
    const display = computed(() => {
        const { loadingIsVisible } = storeToRefs(loadingStore());
        return show.value || loadingIsVisible.value;
    });
    //#endregion
</script>

<style scoped>
    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading {
        align-self: center;
    }
</style>
